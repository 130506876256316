import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import PretendardText from "../../../components/atoms/text/pretendardText";
import BodyConatin from "../../../components/molecules/layout/bodyContain";

import useDisplay from "../../../hooks/useDisplay";
import usePdfDownload from "../../../hooks/usePdfDownload";
import { noticeLang } from "../../../lang/investment/noticeLang";
import { route } from "../../../router/route";
import { langState } from "../../../store/langState";
import { data } from "../../home/datas/managementInfo";
import PDFViewer from "./ReactPDF";

const ManagementInfoDetail = () => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigate = useNavigate();
  const { id } = useParams();

  const item = data.dataList[id - 1];

  const langValue = useRecoilValue(langState);

  const [dataInfo, setDataInfo] = useState(item);
  const [preDataInfo, setPreDataInfo] = useState();
  const [nextDataInfo, setNextDataInfo] = useState();

  const { downloadPdf } = usePdfDownload();

  useEffect(() => {
    getMMInfoDetailAPI();
  }, [id]);

  const getMMInfoDetailAPI = async () => {
    try {
      // const response = await MMInfoApi.GetDetail(id);

      // setDataInfo(response.data.data.now);
      // setPreDataInfo(response.data.data.prev);
      // setNextDataInfo(response.data.data.next);
      setDataInfo(item);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <BodyConatin>
        <PretendardText
          style={{
            color: "#262C31",
            fontSize: isMobile ? "22px" : isTablet ? "25px" : "28px",
            fontWeight: 500,
            marginBottom: isMobile ? 10 : isTablet ? 14 : 32,
            lineHeight: isMobile ? "31.9px" : isTablet ? "35px" : "40.6px",
          }}
        >
          {dataInfo ? dataInfo.title : ""}
        </PretendardText>
        <PretendardText
          style={{
            color: "#ADB4BA",
            fontSize: isMobile ? "15px" : isTablet ? "16px" : "20px",
            fontWeight: isSmall ? 400 : 600,
            lineHeight: isMobile ? "21px" : isTablet ? "22px" : "29px",
          }}
        >
          {dataInfo ? moment.utc(dataInfo.createdAt).format("YYYY.MM.DD") : ""}
        </PretendardText>

        <div
          style={{
            width: "100%",
            borderBottom: "1px solid #ADB4BA",
            marginTop: isSmall ? 25 : 26,
            marginBottom: 25,
          }}
        />

        <div
          style={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            height: "100%",
            marginBottom: 25,
          }}
        >
          <div
            className="ql-editor"
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{
              __html: dataInfo ? dataInfo.content : "",
            }}
          />
          <PDFViewer url={dataInfo ? dataInfo.fileUrl : ""} />
        </div>

        {dataInfo && dataInfo.fileUrl ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: 60,
              marginBottom: 26,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                background: "#F1F1F1",
                height: "100%",
                width: 146,
              }}
            >
              <PretendardText
                style={{
                  color: "#262C31",
                  fontSize: isSmall ? "14px" : "16px",
                  fontWeight: 600,
                  lineHeight: isSmall ? "18.9px" : "23.2px",
                  marginRight: 5,
                }}
              >
                {langValue === "KR" ? "첨부파일" : "File"}
              </PretendardText>
              <img style={{}} src="/assets/icons/file02.svg" alt="file02" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#F9F9F9",
                height: "100%",
                padding: "0px 20px",
                flex: 1,
              }}
            >
              <FileNameText
                className="custom-cursor"
                $isSmall={isSmall}
                onClick={(e) => {
                  downloadPdf({
                    src: dataInfo.fileUrl,
                    downloadFileName: dataInfo.originFileName,
                  });
                }}
              >
                {dataInfo.originFileName}
              </FileNameText>
            </div>
          </div>
        ) : null}

        {preDataInfo ? (
          <div
            onClick={() => {
              navigate(`${route.managementInfoDetail}/${preDataInfo.id}`);
            }}
            className="custom-cursor"
            style={{
              height: isSmall ? 86 : 80,
              display: "flex",
              flexDirection: "row",
              alignItems: isSmall ? "flex-start" : "center",
              paddingTop: isSmall ? 20 : 0,
              width: "100%",
              borderTop: "1px solid #ADB4BA",
              borderBottom: "1px solid #ADB4BA",
              // cursor: "pointer",
            }}
          >
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: isSmall ? "16px" : "18px",
                fontWeight: 600,
                lineHeight: isSmall ? "23.2px" : "26.1px",
                width: isSmall ? 50 : 95,
                borderRight: "1px solid #ADB4BA",
              }}
            >
              Prev
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                alignItems: isSmall ? "flex-start" : "center",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  color: "#5A636A",
                  fontSize: isSmall ? "16px" : "18px",
                  fontWeight: isSmall ? 400 : 600,
                  lineHeight: isSmall ? "23.2px" : "26.1px",
                  marginLeft: isSmall ? 15 : 20,
                  flex: 1,
                }}
              >
                {preDataInfo.title}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: isSmall ? "13px" : "18px",
                  fontWeight: 400,
                  lineHeight: isSmall ? "17.55px" : "26.1px",
                  marginRight: isSmall ? 0 : 30,
                  marginLeft: isSmall ? 22 : 0,
                  marginTop: isSmall ? 5 : 0,
                }}
              >
                {moment.utc(preDataInfo.createdAt).format("YYYY.MM.DD")}
              </PretendardText>
            </div>
          </div>
        ) : null}

        {nextDataInfo ? (
          <div
            onClick={() => {
              navigate(`${route.managementInfoDetail}/${nextDataInfo.id}`);
            }}
            className="custom-cursor"
            style={{
              height: isSmall ? 86 : 80,
              display: "flex",
              flexDirection: "row",
              alignItems: isSmall ? "flex-start" : "center",
              paddingTop: isSmall ? 20 : 0,
              width: "100%",
              borderBottom: "1px solid #ADB4BA",
              borderTop: preDataInfo ? "none" : "1px solid #ADB4BA",
              // cursor: "pointer",
            }}
          >
            <PretendardText
              style={{
                color: "#262C31",
                fontSize: isSmall ? "16px" : "18px",
                fontWeight: 600,
                lineHeight: isSmall ? "23.2px" : "26.1px",
                width: isSmall ? 50 : 95,
                borderRight: "1px solid #ADB4BA",
              }}
            >
              Next
            </PretendardText>
            <div
              style={{
                display: "flex",
                flexDirection: isSmall ? "column" : "row",
                alignItems: isSmall ? "flex-start" : "center",
                flex: 1,
              }}
            >
              <PretendardText
                style={{
                  color: "#5A636A",
                  fontSize: isSmall ? "16px" : "18px",
                  fontWeight: isSmall ? 400 : 600,
                  lineHeight: isSmall ? "23.2px" : "26.1px",
                  marginLeft: isSmall ? 15 : 20,
                  flex: 1,
                }}
              >
                {nextDataInfo.title}
              </PretendardText>
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: isSmall ? "13px" : "18px",
                  fontWeight: 400,
                  lineHeight: isSmall ? "17.55px" : "26.1px",
                  marginRight: isSmall ? 0 : 30,
                  marginLeft: isSmall ? 22 : 0,
                  marginTop: isSmall ? 5 : 0,
                }}
              >
                {moment.utc(nextDataInfo.createdAt).format("YYYY.MM.DD")}
              </PretendardText>
            </div>
          </div>
        ) : null}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: 40,
          }}
        >
          <ListBtn
            $isSmall={isSmall}
            onClick={() => {
              navigate(`${route.managementInfo}/1`);
            }}
          >
            <PretendardText
              style={{
                color: "#2F3941",
                fontSize: isSmall ? "15px" : "18px",
                fontWeight: 600,
                lineHeight: isSmall ? "21px" : "26.1px",
              }}
            >
              {noticeLang.list[langValue]}
            </PretendardText>
          </ListBtn>
        </div>
      </BodyConatin>
    </>
  );
};

export default ManagementInfoDetail;

const ListBtn = styled.button`
  min-width: ${(props) => (props.$isSmall ? "122px" : "143px")};
  height: ${(props) => (props.$isSmall ? "44px" : "54px")};
  border: 1px solid #adb4ba;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileNameText = styled(PretendardText)`
  color: rgb(0, 0, 0);
  font-size: ${(props) => (props.$isSmall ? "14px" : "15px")};
  font-weight: 400;
  line-height: ${(props) => (props.$isSmall ? "18.9px" : "21px")};
  /* cursor: pointer; */

  &:hover {
    text-decoration: underline;
  }
`;
