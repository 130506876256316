export const data = {
  keyword: "",
  totalCtn: 1,
  startDate: "2023-01-01",
  endDate: "2023-12-31",
  // ruleData: {
  //   fileUrl: "https://example.com/rules.pdf",
  //   serverFileName: "company_rules_2023.pdf",
  //   originFileName: "회사 규정 2023.pdf",
  // },
  dataList: [
    {
      id: 1,
      title: "신주 발행 공고",
      createdAt: "2024-09-27T09:00:00Z",
      fileUrl: "/assets/pdfs/신주발행공고.pdf",
      serverFileName: "신주발행공고.pdf",
      originFileName: "신주발행공고.pdf",
    },
    // {
    //   id: 2,
    //   title: "신규 이사 선임 공고",
    //   createdAt: "2023-05-20T14:30:00Z",
    // },
    // {
    //   id: 3,
    //   title: "2023년 반기 경영 실적",
    //   createdAt: "2023-07-31T11:00:00Z",
    //   fileUrl: "https://example.com/half_year_report.pdf",
    //   serverFileName: "half_year_report_2023.pdf",
    //   originFileName: "2023_반기_실적보고서.pdf",
    // },
    // {
    //   id: 4,
    //   title: "주주총회 소집 공고",
    //   createdAt: "2023-08-15T10:00:00Z",
    // },
    // {
    //   id: 5,
    //   title: "신규 사업 진출 발표",
    //   createdAt: "2023-09-01T09:30:00Z",
    //   fileUrl: "https://example.com/new_business.pdf",
    //   serverFileName: "new_business_announcement.pdf",
    //   originFileName: "신규사업_발표자료.pdf",
    // },
  ],
  pagination: {
    size: 1,
    rangeSize: 1,
    totalCount: 1,
    totalPages: 1,
    first: true,
    last: false,
  },
};
