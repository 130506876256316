import React from "react";
import { useNavigate } from "react-router-dom";

import usePdfDownload from "../../../hooks/usePdfDownload";
import PretendardText from "../../atoms/text/pretendardText";

function MIDCard({ style, title, date, number, data, link }) {
  const navigation = useNavigate();

  const { downloadPdf } = usePdfDownload();

  return (
    <>
      <div
        onClick={() => {
          navigation(link);
        }}
        style={{
          display: "flex",
          // cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          background: "#fff",
          borderBottom: "1px solid #ADB4BA",
          height: 66,
          width: "100%",
          padding: "0px 30px",

          ...style,
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.background = "#f5f5f5";
        }} // 추가: hover 시 배경색 변경
        onMouseLeave={(e) => {
          e.currentTarget.style.background = "#fff";
        }} // 추가: hover 해제 시 원래 색으로 복원
      >
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#778088",
            width: 72,
          }}
        >
          {number}
        </PretendardText>

        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "26.1px",
            color: "#262C31",
            flex: 1,
          }}
        >
          {title}
        </PretendardText>

        {data.fileUrl ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              downloadPdf({
                src: data.fileUrl,
                downloadFileName: data.serverFileName,
              });
            }}
            className="custom-cursor"
            style={{
              border: "1px solid #ADB4BA",
              width: 135,
              height: 43,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              // cursor: "pointer",
              marginRight: 30,
            }}
          >
            <PretendardText
              style={{
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "21px",
                color: "#262C31",
                marginRight: 5,
              }}
            >
              파일 다운로드
            </PretendardText>
            <img
              style={{}}
              src="/assets/icons/download_gray.svg"
              alt="download"
            />
          </div>
        ) : null}

        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "26.1px",
            color: "#ADB4BA",
          }}
        >
          {date}
        </PretendardText>
      </div>
    </>
  );
}

export default MIDCard;
